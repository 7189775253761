import {IInputProps, Input} from 'native-base';
import React, {useCallback} from 'react';
import {TextInput} from 'react-native';
import {useBottomSheetInternal} from '@gorhom/bottom-sheet';

interface Props extends IInputProps {
    inputRef?: React.RefObject<TextInput | undefined>;
}

const BottomSheetTextInput = (props: Props) => {

    // #region hooks
    const {shouldHandleKeyboardEvents} = useBottomSheetInternal();
    // #endregion

    // #region callbacks
    const handleOnFocus = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (args: any) => {
            shouldHandleKeyboardEvents.value = true;
            props.onFocus?.(args);
        },
        [shouldHandleKeyboardEvents],
    );
    const handleOnBlur = useCallback(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (args: any) => {
            shouldHandleKeyboardEvents.value = false;
            props.onBlur?.(args);
        },
        [shouldHandleKeyboardEvents],
    );
    // #endregion

    return (
        <Input {...props} ref={props.inputRef} onBlur={handleOnBlur} onFocus={handleOnFocus} />
    );
};

export default BottomSheetTextInput;
