import {
    Box,
    Menu,
    Text,
} from 'native-base';
import NavigationLink from '@ui/components/buttons/NavigationLink';
import React from 'react';

interface HeaderMenuItemProps {
    title: string;
    isSelected: boolean;
    path: string;
}

const HeaderMenuItem = (props: HeaderMenuItemProps) => {
    const {title, path, isSelected} = props;

    return (
        <Box borderBottomWidth={isSelected ? 2 : 0} borderBottomColor='black'>
            <NavigationLink py={6} px={2} path={path}>
                <Text variant='medium'>
                    {title}
                </Text>
            </NavigationLink>
        </Box>
    );
};

export const HeaderSelectMenuItem = (props: HeaderMenuItemProps) => {
    const {title, path, isSelected} = props;

    return (
        <Box
            bg={isSelected ? 'light.100' : 'transparent'}
        >
            <Menu.Item
                flex={1}
                textValue={title}
                _focus={{bg: 'transparent'}}
                _hover={{bg: 'transparent'}}
            >
                <NavigationLink py={2} px={2} path={path}>
                    <Text
                        variant='mediumBody'
                    >
                        {title}
                    </Text>
                </NavigationLink>

            </Menu.Item>
        </Box>
    );
};

export default HeaderMenuItem;
