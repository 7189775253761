import React, {useState} from 'react';
import {UserProvider, useUser} from '@ui/provider/user/UserProvider';
import {AuthMethodSelectScreenSignIn} from '@ui/features/auth/AuthMethodSelectScreen';
import BottomsheetProps from '@ui/components/sheet/BottomsheetProps';
import CarouselBottomsheet from '@ui/components/carousel/CarouselBottomsheet';
import {ForgotPasswordScreen} from '@ui/features/auth/password/ForgotPassword';
import {LoginForm} from '@ui/features/auth/login/LoginForm';
import {Platform} from 'react-native';
import {RegisterForm} from '../register/RegisterScreen';
import {checkIfEmailExists} from '../hooks/checkIfEmailExists';
import {useBreakpointValue} from 'native-base';
import useCrouton from '@ui/hooks/useCrouton';
import {useTranslation} from 'react-i18next';

const FIRST_SCREEN_INDEX = 0;
const LOGIN_FORM_INDEX = 1;
const LOST_PASSWORD_INDEX = 2;
const REGISTER_FORM_INDEX = 3;

interface Props extends Omit<BottomsheetProps, 'children'> {
    onFinish: () => void;
}

const LoginModal = (props: Props) => {
    const {onFinish} = props;
    const {t} = useTranslation();
    const [page, setPage] = useState<number>(0);
    const [email, setEmail] = useState<string>('');
    const [isEmailLoading, setIsEmailLoading] = useState<boolean>(false);
    const crouton = useCrouton();

    const webSnapPoints = useBreakpointValue({
        base: ['90%', '70%', '70%', '90%'],
        lg: ['80%', '75%', '75%', '80%'],
    });

    const userProvider = useUser();

    const paginate = (page: number) => () => {
        setPage(Math.max(0, page));
    };

    const onClose = () => {
        setPage(0);
        props.onClose();
    };

    const onFinished = () => {
        setPage(0);
        onFinish();
    };

    const onPressEmail = async() => {
        setIsEmailLoading(true);
        const exists = await checkIfEmailExists(email);
        setIsEmailLoading(false);
        if(exists === 'social') {
            crouton.show({
                title: t('auth.emailIsSocialLoginTitle'),
                message: t('auth.emailIsSocialLoginSubtitle'),
                preset: 'danger',
            });
            return;
        }

        if (exists) {
            setPage(LOGIN_FORM_INDEX);
        } else {
            setPage(REGISTER_FORM_INDEX);
        }
    };

    return (
        <CarouselBottomsheet
            isOpen={props.isOpen}
            onClose={onClose}
            titles={[t('auth.signIn.signIn'), t('auth.signIn.signIn'), t('auth.forgotPassword.title')]}
            page={page}
            onBack={
                page === FIRST_SCREEN_INDEX ? undefined :
                    page === LOST_PASSWORD_INDEX ? paginate(LOGIN_FORM_INDEX) : paginate(FIRST_SCREEN_INDEX)
            }
            snapPoints={Platform.select({
                web: webSnapPoints,
                default: ['100%', '50%', '50%', '75%'],
            })}
        >
            <UserProvider key={FIRST_SCREEN_INDEX} value={userProvider}>
                <AuthMethodSelectScreenSignIn
                    onFinish={onFinished}
                    onPressEmail={(onPressEmail)}
                    email={email}
                    setEmail={setEmail}
                    isEmailLoading={isEmailLoading}
                />
            </UserProvider>

            <UserProvider key={LOGIN_FORM_INDEX} value={userProvider}>
                <LoginForm onFinish={onFinished} onPressForgotPassword={paginate(LOST_PASSWORD_INDEX)} email={email} />
            </UserProvider>

            <UserProvider key={LOST_PASSWORD_INDEX} value={userProvider}>
                <ForgotPasswordScreen onFinish={paginate(LOGIN_FORM_INDEX)}/>
            </UserProvider>

            <UserProvider key={REGISTER_FORM_INDEX} value={userProvider}>
                <RegisterForm onFinish={onFinished} bottomSheet email={email}/>
            </UserProvider>
        </CarouselBottomsheet>
    );
};

export default LoginModal;
