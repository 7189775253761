import FloatingLabelView, {FloatingLabelProps} from './FloatingLabelView';
import {IBoxProps, IInputProps, ITextProps, Input, useBreakpointValue} from 'native-base';
import React, {useCallback} from 'react';
import {TextInput} from 'react-native';
import {useBottomSheetInternal} from '@gorhom/bottom-sheet';

export interface FloatingLabelInputProps extends IInputProps, FloatingLabelProps {
    _text?: ITextProps;
    focus?: boolean;
    inputRef?: React.RefObject<TextInput | undefined>;
    bottomSheet?: boolean;
    _wrapper?: IBoxProps;
}

const FloatingLabelInput = (props: FloatingLabelInputProps) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {_text, bottomSheet = useBreakpointValue({base: true, md: false}), onFocus, onBlur, inputRef, labelBGColor = 'white', _wrapper = {}, ...restProps} = props;
    const [isFocused, setIsFocused] = React.useState(false);

    // #region hooks
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {shouldHandleKeyboardEvents} = bottomSheet ? useBottomSheetInternal() : {shouldHandleKeyboardEvents: {value: false}};
    // #endregion

    // #region callbacks
    const handleOnFocus = useCallback(
        (args: any) => {
            shouldHandleKeyboardEvents.value = true;
            setIsFocused(true);
            onFocus?.(args);
        },
        [onFocus, shouldHandleKeyboardEvents],
    );
    const handleOnBlur = useCallback(
        (args: any) => {
            shouldHandleKeyboardEvents.value = false;
            setIsFocused(false);
            onBlur?.(args);
        },
        [onBlur, shouldHandleKeyboardEvents],
    );
    // #endregion

    const maxLength = restProps.maxLength;

    return (
        <FloatingLabelView
            isFocused={isFocused}
            hasValue={maxLength !== undefined}
            labelBGColor={labelBGColor}
            label={`${props.value?.length ?? props.defaultValue?.length ?? 0}/${maxLength}`}
            _wrapper={_wrapper}
            labelPosition='right'
            isVisible={maxLength !== undefined}
        >
            <FloatingLabelView
                isFocused={isFocused}
                hasValue={!!restProps.value || !!restProps.defaultValue || !!restProps.placeholder}
                labelBGColor={labelBGColor}
                label={restProps.label ?? ''}
                _wrapper={_wrapper}
                isRequired={restProps.isRequired}
            >
                <Input
                    borderRadius={4}
                    bg='white'
                    {...restProps}
                    {..._text}
                    ref={inputRef}
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    _focus={{bg: labelBGColor}}
                    _hover={{bg: labelBGColor}}
                    fontSize={14}
                />
            </FloatingLabelView>
        </FloatingLabelView>
    );
};

export default FloatingLabelInput;
