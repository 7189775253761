import {IRadioGroupProps, Radio} from 'native-base';
import React from 'react';
import {useTranslation} from 'react-i18next';

export interface RadioOption {
    value: string;
    translationKey: string;
}

interface RadioGroupProps extends IRadioGroupProps {
  options: RadioOption[];
  setValue: (option: string) => void;
}

const RadioGroup = (props: RadioGroupProps) => {
    const {options, value, setValue} = props;

    const {t} = useTranslation();

    return (
        <Radio.Group
            {...props}
            onChange={setValue}
            value={value}
        >
            {options.map((option, index) => (
                <Radio
                    key={index}
                    value={option.value}
                >
                    {t(option.translationKey)}
                </Radio>
            ))}
        </Radio.Group>
    );
};

export default RadioGroup;
