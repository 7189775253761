import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const IcWAMP = (props: SvgProps) => (
    <Svg
        width={104}
        height={55}
        fill="none"
        viewBox='0 0 104 55'
        {...props}
    >
        <Path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40.213 27.04v27.733L0 27.04V0l40.213 27.04Zm40.214 0L40.213 0v27.04l40.214 27.733V27.04Zm0 0V0L104 13.52 80.427 27.04Z"
            fill={props.fill || '#CFFE80'}
        />
    </Svg>
);

export default IcWAMP;
