import {AnimatePresence, MotiView} from 'moti';
import React from 'react';
import {StyleSheet} from 'react-native';

interface CarouselFadeInViewProps {
    direction: number;
    width: number;
    index: number;
    children: JSX.Element[];
}

const CarouselFadeInView = (props: CarouselFadeInViewProps) => {
    const {direction, width, index, children} = props;
    return (
        <AnimatePresence initial={false} exitBeforeEnter custom={direction}>
            <MotiView
                from={{
                    opacity: 0,
                    translateX: direction * width,
                }}
                animate={{
                    opacity: 1,
                    translateX: 0,
                    zIndex: 1,
                }}
                exit={(custom) => {
                    'worklet';
                    return {
                        opacity: 0,
                        translateX: custom * width * -1,
                        zIndex: 0,
                    };
                }}
                key={index}
                transition={{
                    translateX: {type: 'spring', stiffness: 300, damping: 30},
                    opacity: {duration: 100, type: 'timing'},
                }}
                style={styles.container}
            >
                {children[index]}
            </MotiView>
        </AnimatePresence>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

export default CarouselFadeInView;
