import {IVStackProps} from 'native-base/lib/typescript/components/primitives/Stack/VStack';

type DatePickerType = 'date' | 'time' | 'datetime';

export type DateTimePickerProps = {
    open?: boolean;
    onChange: (date: Date) => void;
    value: Date;
    dateText?: string;
    disabled?: boolean;
    type?: DatePickerType;
    minimumDate?: Date;
    maximumDate?: Date;
    onClose?: () => void;
    // web only
    customInput?: React.ReactNode | undefined;
    _container?: IVStackProps;
};


export const datePickerFormat = (type?: DatePickerType) =>
    type === 'datetime'
        ? 'MMMM d, yyyy h:mm aa'
        : type === 'time'
            ? 'h:mm aa'
            : 'MMMM d, yyyy';
