import {Button} from 'native-base';
import IcGoogleLogo from '@ui/svg/IcGoogleLogo';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface Props {
    text?: string;
}

const GoogleLoginButton = (props: Props) => {
    const {text} = props;
    const {logInWithSocial, isLogInWithGoogleLoading} = useUser();
    const {t} = useTranslation();

    const onPress = async() => {
        logInWithSocial('google');
    };

    return (
        <Button
            variant="outline"
            onPress={onPress}
            isLoading={isLogInWithGoogleLoading}
            leftIcon={<IcGoogleLogo />}
            _text={{
                color: 'black',
            }}
        >
            {text ?? t('auth.signIn.signInWithGoogle')}
        </Button>
    );
};

export default GoogleLoginButton;
