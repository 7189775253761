import {Box, Center, Column, IAvatarProps, IBoxProps, IStackProps, ITextProps, Row, Stack, Text} from 'native-base';
import {get, isString} from 'lodash';
import {getAvatarSize, getMissingAvatarFontSize, randomColorFromString, usernameToInitialsArray} from '@shared/helpers/user-fn';
import {CategoryUiData} from '@shared/models/category';
import {EntityUiData} from '@shared/models/entity';
import {ImageUris} from '@shared/models/image';
import {Participant} from '@shared/models/participant';
import {Platform} from 'react-native';
import React from 'react';
import {ResponsiveValue} from 'native-base/lib/typescript/components/types';
import WAMPImage from '../image/WAMPImage';
import {isNil} from 'lodash';
import {useTranslation} from 'react-i18next';

interface ParticipantAvatarImageProps extends IBoxProps {
    avatar?: ImageUris;
    username?: string;
}

export const ParticipantAvatarImage = (props: ParticipantAvatarImageProps) => {
    const {avatar, username, ...rest} = props;

    const avatarBackgroundColor = randomColorFromString(username);

    return (
        <Center
            overflow='hidden'
            rounded='full'
            bg={`${avatarBackgroundColor}.500`}
            {...rest}
        >
            {avatar ?
                <WAMPImage
                    contentFit='cover'
                    src={avatar.xl}
                    image={avatar}
                    fill
                    alt={username ?? ''}
                /> :

                <Row>
                    {usernameToInitialsArray(username, 2).map((initial, index) => (
                        <Text
                            key={index}
                            variant="strongH3"
                            fontSize={24}
                            italic
                            color="white"
                            lineHeight={Platform.select({
                                ios: 0,
                                android: 24 + 6,
                                web: 0,
                            })}
                        >
                            {initial}
                        </Text>
                    ))}
                </Row>
            }
        </Center>
    );
};


interface ParticipantAvatarProps extends IStackProps {
    participant?: Participant | EntityUiData | CategoryUiData;
    _avatar: IAvatarProps;
    _text?: ITextProps;
    avatarTextShifted?: boolean;
    name?: string;
    showName?: boolean;
    subtitle?: string;
    rounded?: ResponsiveValue<'xl' | 'xs' | 'sm' | 'md' | 'lg' | '2xl' | (string & object) | (number & object) | 'full' | '3xl' | 'none'>;
    avatarIcon?: React.ReactNode;
    showInitials?: boolean;
    leftText?: JSX.Element;
}

const isParticipantEntity = (participant?: Participant | EntityUiData | CategoryUiData): participant is EntityUiData | CategoryUiData => {
    if (!participant) {
        return false;
    }

    return (participant as EntityUiData).logo !== undefined || (participant as EntityUiData).name !== undefined;
};

const ParticipantAvatar = (props: ParticipantAvatarProps) => {
    const {participant, _avatar, _text, avatarTextShifted = false, name, showName = true, subtitle, avatarIcon, showInitials = true, leftText, ...rest} = props;
    const {t} = useTranslation();

    const avatarSize = isString(_avatar?.size) ? _avatar.size : 'md';
    const isParticipant = !isNil(participant);

    const noOpponentSize = getAvatarSize(avatarSize);
    const missingAvatarFontSize = getMissingAvatarFontSize(avatarSize);

    const isEntity = isParticipantEntity(participant);

    const username = isEntity ? participant?.name?.en : participant?.username;
    const avatar = isEntity ? participant.logo : participant?.avatar;

    const avatarUri = get(avatar, 'lg', avatar?.xl);

    const avatarBackgroundColor = randomColorFromString(username);

    const rounded = props.rounded ?? 'full';

    return (
        <Stack
            alignItems='center'
            w={{
                base: 'full',
                lg: 'initial',
            }}
            {...rest}
        >
            <Box
                position='relative'
            >
                <Box
                    {..._avatar}
                    w={noOpponentSize}
                    h={noOpponentSize}
                    overflow='hidden'
                    rounded={rounded}
                >
                    {participant && avatarUri ?
                        <WAMPImage
                            contentFit='cover'
                            src={avatarUri}
                            image={avatar!}
                            fill
                            alt={username ?? ''}
                        /> :

                        <Center
                            width={noOpponentSize}
                            height={noOpponentSize}
                            rounded={rounded}
                            bg={`${avatarBackgroundColor}.500`}
                            overflow="hidden"
                        >
                            {showInitials &&
                            <Row>
                                {usernameToInitialsArray(username, 2).map((initial, index) => (
                                    <Text
                                        key={index}
                                        variant="strongH3"
                                        fontSize={missingAvatarFontSize}
                                        italic
                                        top={avatarTextShifted ? index : 0}
                                        color="white"
                                        lineHeight={Platform.select({
                                            ios: 0,
                                            android: missingAvatarFontSize + 6,
                                            web: 0,
                                        })}
                                    >
                                        {initial}
                                    </Text>
                                ))}
                            </Row>
                            }
                        </Center>
                    }
                </Box>

                {avatarIcon &&
                    <Box
                        position='absolute'
                        right={0}
                    >
                        {avatarIcon}
                    </Box>
                }
            </Box>


            <Column
                maxW={{
                    base: '80%',
                    lg: '90%',
                }}
                space={1}
            >
                <Row>
                    {leftText}

                    {showName &&
                    <Text
                        variant='medium'
                        ellipsizeMode='tail'
                        noOfLines={1}
                        {..._text}
                    >
                        {name ? name : isParticipant ? username : t('fixture.noOpponent')}
                    </Text>
                    }
                </Row>

                {showName && subtitle &&
                <Text
                    variant='body'
                    fontWeight={500}
                    color='blacks.600'
                    ellipsizeMode='tail'
                    noOfLines={1}
                >
                    {subtitle}
                </Text>
                }
            </Column>
        </Stack>
    );
};

export default ParticipantAvatar;
