import {Center, ICenterProps, ISpinnerProps, Spinner} from 'native-base';
import {AnimatePresence} from 'moti';
import FadeInView from './animation/FadeInView';
import React from 'react';
import {StyleSheet} from 'react-native';

interface ILoadingScreenProps extends ICenterProps {
    _spinner?: ISpinnerProps;
}

const LoadingScreen = ({_spinner, ...restProps}: ILoadingScreenProps) => {
    return (
        <Center h="full" w='full' {...restProps}>
            <Spinner size='lg' color='black' {..._spinner}/>
        </Center>
    );
};

interface PresenceProps extends ILoadingScreenProps {
    isLoading: boolean;
    showSpinner?: boolean;
    showContent?: boolean;
}

export const PresenceLoadingScreen = ({_spinner, isLoading, children, showSpinner = true, showContent = true, ...restProps}: PresenceProps) => {
    return (
        <Center h="full" {...restProps}>
            <AnimatePresence exitBeforeEnter>
                {isLoading && showSpinner && (<FadeInView key="loader" style={styles.presenceWrapper}><Spinner size='lg' color='black' h='300' {..._spinner}/></FadeInView>)}

                {!isLoading && showContent && (<FadeInView key="children" style={styles.presenceWrapper}>{children}</FadeInView>)}
            </AnimatePresence>
        </Center>
    );
};

const styles = StyleSheet.create({
    presenceWrapper: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
});

export default LoadingScreen;
