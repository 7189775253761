import {Button, Text} from 'native-base';
import IcAppleLogo from '@ui/svg/IcAppleLogo';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface Props {
    text?: string;
}

const AppleLoginButton = (props: Props) => {
    const {text} = props;
    const {logInWithSocial, isLogInWithAppleLoading} = useUser();

    const {t} = useTranslation();

    const onPress = async() => {
        logInWithSocial('apple');
    };

    return (
        <Button
            onPress={onPress}
            isLoading={isLogInWithAppleLoading}
            leftIcon={<IcAppleLogo />}
        >
            <Text color="white">
                {text ?? t('auth.signIn.signInWithApple')}
            </Text>
        </Button>
    );
};

export default AppleLoginButton;
