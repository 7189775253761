import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const IcArrowForward = (props: SvgProps) => (
    <Svg
        viewBox='0 0 24 24'
        width={24}
        height={24}
        fill="#151515"
        {...props}
    >
        <Path
            d="M16.627 12.75H4.5v-1.5h12.127L10.93 5.554 12 4.5l7.5 7.5-7.5 7.5-1.07-1.054 5.697-5.696Z"
        />
    </Svg>
);
export default IcArrowForward;
