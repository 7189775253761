import {Hidden, View} from 'native-base';
import DownloadAppSmallScreen from '@ui/components/appStore/DownloadAppSmallScreen';
import {IViewProps} from 'native-base/lib/typescript/components/basic/View/types';
import React from 'react';
import ScreenSizeWarning from '@ui/components/ScreenSizeWarning';

interface Props {
    children: JSX.Element;
    isOrganizer?: boolean;
    _view?: IViewProps;
}

const DesktopOnlyWrapper = (props: Props) => {
    const {children, isOrganizer = false, _view} = props;

    return (
        <View flex={1} {..._view}>
            <Hidden till='md'>
                {children}
            </Hidden>

            <Hidden from='md'>
                {isOrganizer ?
                    <ScreenSizeWarning /> :
                    <DownloadAppSmallScreen />
                }
            </Hidden>
        </View>
    );
};

export default DesktopOnlyWrapper;
