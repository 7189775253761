// Chromium regex
const expression = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const trimObject = (obj: any): object => {
    return Object.keys(obj).reduce((acc, next) => {
        if (typeof obj[next] === 'string') {
            return {...acc, [next]: obj[next].trim()};
        } else if (typeof obj[next] === 'object') {
            return trimObject(obj[next]);
        }

        return {...acc, [next]: obj[next]};
    }, {});
};

export const validateEmail = (email: string) => expression.test(String(email).toLowerCase());

export const removeNonNumbers = (value: string) => value.replace(/[^\d.-]/g, '');

export const getFlagEmoji = (countryCode: string) => {
    return countryCode.toUpperCase().replace(/./g, char => String.fromCodePoint(char.charCodeAt(0) + 127397));
};
