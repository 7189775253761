export const MAX_HCP = 54;
export const MIN_HCP = -10;

export const TERMS_CONDITIONS_URL = 'terms-conditions';
export const TOURNAMENT_ORGANIZER_SIGN_UP = 'https://wamp.app/early-access/';
export const INFO_EMAIL = 'info@wamp.app';

export const WEB_LG_MAX_WIDTH = 740;


export const HEADER_MAX_HEIGHT = 500;
export const HEADER_MIN_HEIGHT = 50;
export const DETAILS_HEIGHT = 120;


export const COMPETITION_DETAILS_HEIGHT = 380;

export const HEADER_SCROLL_DISTANCE = HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT - DETAILS_HEIGHT;
