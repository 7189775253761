import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const IcPhoneCrossed = (props: SvgProps) => (
    <Svg
        width={87}
        height={88}
        fill="none"
        {...props}
    >
        <Path
            fill="#000"
            d="M19.23.667c-.44.05-.78.186-1.22.406l12.594 12.594h33.313v33.312l8.666 8.667V9.333c0-4.773-3.893-8.666-8.666-8.666H20.583c-.44 0-.914-.051-1.354 0Zm-13.136.677L0 7.437l11.917 11.917v59.313c0 4.773 3.893 8.666 8.666 8.666h43.334c4.35 0 7.939-3.25 8.53-7.448l7.855 7.855 6.094-6.094L6.094 1.344Zm14.49 26.677L62.561 70H20.584V28.02ZM42.25 74.333c2.607 0 4.333 1.727 4.333 4.334 0 2.606-1.726 4.333-4.333 4.333s-4.333-1.727-4.333-4.333c0-2.607 1.726-4.334 4.333-4.334Z"
        />
    </Svg>
);
export default IcPhoneCrossed;
