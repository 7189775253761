import {
    Box,
    Button, Checkbox,
    Column, Text,
} from 'native-base';
import {EmailFormField, PasswordFormField} from '@ui/components/forms/Fields';
import {Keyboard, Platform, TextInput, TouchableOpacity, TouchableWithoutFeedback} from 'react-native';
import React, {useEffect, useState} from 'react';
import BottomSheetScrollView from '@ui/components/gorhom/BottomSheetScrollView';
import useTrackButtonClick from '@ui/helpers/analytics/analytics-hooks';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface FormProps {
    onFinish: (newUser?: boolean) => void;
    onPressForgotPassword: () => void;
    buttonText?: string;
    bottomSheet?: boolean;
    email?: string;
}

export const LoginForm = (props: FormProps) => {
    const {onFinish, buttonText, onPressForgotPassword, bottomSheet = true} = props;

    const {logIn, isLoggedIn} = useUser();
    const [isLoading, setIsLoading] = useState(false);

    const {t} = useTranslation();

    const [email, setEmail] = useState(props.email ?? '');
    const [password, setPassword] = useState('');
    const [showPass, setShowPass] = React.useState(false);
    const [rememberMe, setRememberMe] = React.useState(true);

    const emailRef = React.useRef<TextInput>();
    const passwordRef = React.useRef<TextInput>();

    const buttonDisabled = [email, password].some((v) => v === '');

    const trackButtonClick = useTrackButtonClick();

    useEffect(() => {
        if (isLoggedIn) {
            onFinish();
            setIsLoading(false);
        }
    }, [isLoggedIn]);

    const onPressLogin = async() => {
        if (buttonDisabled) {
            return;
        }

        Keyboard.dismiss();

        setIsLoading(true);
        const response = await logIn(email, password, rememberMe);

        if (!response) {
            setIsLoading(false);
            return;
        }
    };

    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} disabled={Platform.OS === 'web'}>
            <BottomSheetScrollView>
                <Column
                    bg='white'
                    p={4}
                    safeAreaBottom
                    h='full'
                    justifyContent="space-between"
                >
                    <Column flex={2}>
                        <EmailFormField
                            defaultValue={email}
                            onChangeText={setEmail}
                            inputRef={emailRef}
                            onSubmitEditing={() => passwordRef.current?.focus()}
                            bottomSheet={bottomSheet}
                            autoFocus={props.email === undefined}
                        />

                        <PasswordFormField
                            defaultValue={password}
                            onChangeText={setPassword}
                            inputRef={passwordRef}
                            onSubmitEditing={onPressLogin}
                            returnKeyType='done'
                            showPass={showPass}
                            setShowPass={setShowPass}
                            bottomSheet={bottomSheet}
                            autoFocus={props.email !== undefined}
                        />

                        <TouchableOpacity onPress={onPressForgotPassword}>
                            <Text
                                variant='mediumBody'
                                w='full'
                                textAlign='right'
                                my='1'
                            >
                                {t('auth.signIn.forgotPassword')}
                            </Text>
                        </TouchableOpacity>
                    </Column>

                    <Box h='8' />

                    <Column
                        space={{
                            base: 2,
                            lg: 5,
                        }}
                        justifyContent='flex-end'
                        flex={1}
                    >
                        {Platform.OS === 'web' && (
                            <Checkbox
                                alignItems='flex-start'
                                mt='5'
                                isChecked={rememberMe}
                                onChange={checked => setRememberMe(checked)}
                                value='rememberMe'
                                accessibilityLabel={t(
                                    'auth.signIn.rememberMe.accessabilityLabel',
                                )}
                                _checked={{
                                    bg: 'black',
                                    borderColor: 'black',
                                    _pressed: {
                                        bg: 'secondary.800',
                                        borderColor: 'secondary.700',
                                    },
                                }}
                                _focus={{
                                    borderColor: 'black',
                                }}
                            >
                                <Text
                                    pl='3'
                                    fontWeight='normal'
                                    _light={{color: 'coolGray.800'}}
                                    _dark={{color: 'coolGray.400'}}
                                >
                                    {t('auth.signIn.rememberMe.text')}
                                </Text>
                            </Checkbox>
                        )}

                        <Button
                            size='md'
                            variant='primary'
                            onPress={trackButtonClick('Sign In', {type: 'email'}, onPressLogin)}
                            isDisabled={buttonDisabled}
                            isLoading={isLoading}
                        >
                            {buttonText ?? t('auth.signIn.signIn')}
                        </Button>
                    </Column>
                </Column>
            </BottomSheetScrollView>
        </TouchableWithoutFeedback>
    );
};
