import {fetchSignInMethodsForEmail, getAuth} from 'firebase/auth';

const checkIfEmailExists = async(email: string) => {
    const auth = getAuth();
    const response = await fetchSignInMethodsForEmail(auth, email);

    if(response.length === 0) {
        return false;
    }

    if(response.includes('password')) {
        return true;
    }

    return 'social';
};

export {checkIfEmailExists};
