import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

function IcBurger(props: SvgProps) {
    return (
        <Svg
            width={24}
            height={24}
            viewBox="0 0 25 25"
            stroke="#1C1B1F"
            {...props}
        >

            <Path d="M3.97112 12.5723H21.9711" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>

            <Path d="M3.97112 6.57227H21.9711" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>

            <Path d="M3.97112 18.5723H21.9711" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
    );
}

export default IcBurger;
