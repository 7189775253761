import {AnimatePresence, MotiView} from 'moti';
import {Box, IInputProps, IStackProps, IconButton, Input, Pressable, Stack, Text, useDisclose, useTheme} from 'native-base';
import {DateTimePickerProps, datePickerFormat} from '@ui/components/datepicker/DatePickerProps';
import FloatingLabelInput, {FloatingLabelInputProps} from '@ui/components/FloatingLabelInput';
import {Keyboard, Platform, StyleSheet, TextInput} from 'react-native';
import RadioGroup, {RadioOption} from '../RadioGroup';
import BottomSheetTextInput from '../sheet/BottomSheetTextInput';
import DateTimePicker from '@ui/components/datepicker/DatePicker';
import {Gender} from '@shared/models/user';
import IcCalendar from '@ui/svg/IcCalendar';
import IcCircleCross from '@ui/svg/IcCircleCross';
import IcEye from '@ui/svg/IcEye';
import IcEyeLine from '@ui/svg/IcEyeLine';
import IcSearch from '@ui/svg/IcSearch';
import React from 'react';
import {format} from 'date-fns';
import useColors from '@ui/hooks/native-base/use-colors';
import {useTranslation} from 'react-i18next';

interface FormFieldProps extends Omit<FloatingLabelInputProps, 'label'> {
    inputRef?: React.RefObject<TextInput | undefined>;
    unstyled?: boolean;
}

export const EmailFormField = (props: FormFieldProps) => {
    const {unstyled = false} = props;
    const {t} = useTranslation();

    if(unstyled) {
        return (
            <BottomSheetTextInput
                flex={1}
                isRequired
                placeholder={t('auth.email.text')}
                textContentType='emailAddress'
                keyboardType='email-address'
                autoCapitalize='none'
                autoCorrect={false}
                autoComplete='email'
                returnKeyType='next'
                maxLength={100}
                blurOnSubmit={false}
                ref={props.inputRef}
                variant='unstyled'
                placeholderTextColor="secondary.200"
                fontFamily="Inter"
                fontWeight="600"
                fontSize={30}
                lineHeight={32}
                {...props}
            />
        );
    }

    return (
        <FloatingLabelInput
            isRequired
            label={t('auth.email.text')}
            textContentType='emailAddress'
            keyboardType='email-address'
            autoCapitalize='none'
            autoCorrect={false}
            autoComplete='email'
            returnKeyType='next'
            maxLength={100}
            blurOnSubmit={false}
            inputRef={props.inputRef}
            {...props}
        />
    );
};

interface PasswordFormFieldProps extends FormFieldProps {
    showPass: boolean;
    setShowPass: (showPass: boolean) => void;
    label?: string;
}

export const PasswordFormField = ({showPass, setShowPass, ...props}: PasswordFormFieldProps) => {
    const {t} = useTranslation();
    return (
        <FloatingLabelInput
            isRequired
            autoCapitalize='none'
            autoCorrect={false}
            autoComplete='password'
            textContentType='password'
            type={showPass ? 'text' : 'password'}
            returnKeyType='next'
            maxLength={100}
            blurOnSubmit={false}
            label={props.label ?? t('auth.password.text')}
            inputRef={props.inputRef}
            InputRightElement={
                <IconButton
                    variant='unstyled'
                    icon={
                        showPass ? <IcEye/> : <IcEyeLine />
                    }
                    onPress={() => {
                        setShowPass(!showPass);
                    }}
                />
            }
            {...props}
        />
    );
};

interface InputProps extends IInputProps {
    inputRef?: React.RefObject<TextInput | undefined>;
}

interface RightCrossProps {
    visible: boolean;
    onPress: () => void;
}

const RightCross = (props: RightCrossProps) => {
    const {visible, onPress} = props;
    const theme = useTheme();

    return (
        <AnimatePresence>
            {visible &&
                <MotiView
                    from={{
                        translateX: 100,
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                        translateX: 0,
                    }}
                    exit={{
                        opacity: 0,
                        translateX: 100,

                    }}
                    transition={{
                        type: 'timing',
                        duration: 250,
                    }}
                >
                    <IconButton variant="unstyled" icon={<IcCircleCross fill={theme.colors.secondary[400]} height={20} width={20} border="transparent"/>} onPress={onPress} />
                </MotiView>
            }
        </AnimatePresence>
    );
};

export const SearchField = (props: InputProps) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const {t} = useTranslation();
    const colors = useColors();

    const clearValue = () => {
        Keyboard.dismiss();
        props.onChangeText?.('');
    };

    const showClearButton = (props.value ?? '') !== '';

    return (
        <Input
            ref={props.inputRef}
            autoCapitalize="none"
            autoCorrect={false}
            spellCheck={false}
            autoComplete="off"
            InputLeftElement={
                <Box ml='3'>
                    <IcSearch height={20} width={20} fill={colors.blacks[600]}/>
                </Box>
            }
            w='full'
            InputRightElement={Platform.OS !== 'web' ? <RightCross visible={showClearButton} onPress={clearValue} /> : undefined}
            variant='filled'
            borderWidth="1px"
            rounded='lg'
            minW='350'
            borderColor={isFocused ? 'blacks.600' : 'blacks.200'}
            _focus={{bg: 'blacks.200', borderColor: 'transparent', color: 'blacks.600', placeholderTextColor: 'black'}}
            _hover={{bg: 'blacks.200', borderColor: 'transparent', color: 'blacks.600', placeholderTextColor: 'black'}}
            bg="blacks.200"
            fontSize={17}
            placeholderTextColor="blacks.600"
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            placeholder={t('actions.search')}
            color="blacks.600"
            {...props}
        />
    );
};

const genderOptionsNoOther: RadioOption[] = [
    {
        value: 'male',
        translationKey: 'auth.gender.male',
    },
    {
        value: 'female',
        translationKey: 'auth.gender.female',
    },
];


const genderOptions: RadioOption[] = [
    {
        value: 'male',
        translationKey: 'auth.gender.male',
    },
    {
        value: 'female',
        translationKey: 'auth.gender.female',
    },
    {
        value: 'other',
        translationKey: 'auth.gender.other',
    },
];

interface GenderSelectFieldProps extends IStackProps {
    value: Gender;
    setValue: (value: Gender) => void;
    showOther?: boolean;
}

export const GenderSelectField = (props: GenderSelectFieldProps) => {
    const {value, setValue, showOther = true, ...rest} = props;
    const {t} = useTranslation();

    const onChangeValue = (v: string) => setValue(v as Gender);

    const options = showOther ? genderOptions : genderOptionsNoOther;

    return (
        <Stack direction="column" space={4} {...rest}>
            <Text
                variant='medium'
            >
                {t('auth.gender.text')}
            </Text>

            <RadioGroup
                options={options}
                name='genderSelect'
                accessibilityLabel='users gender'
                value={value}
                setValue={onChangeValue}
                space={4}
                direction='row'
            />
        </Stack>
    );
};


interface DateProps extends DateTimePickerProps {
    label: string;
    isDisabled?: boolean;
}

export const DateTimeField = (props: DateProps) => {
    const {value, type, isDisabled} = props;
    const {isOpen, onOpen, onClose} = useDisclose();

    const onChange = (date: Date) => {
        onClose();
        props.onChange(date);
    };

    return (
        <Pressable isDisabled={isDisabled} onPress={onOpen} flex={1}>
            <FloatingLabelInput
                isRequired
                label={props.label}
                value={format(value, datePickerFormat(type))}
                rightElement={<Box mr='4'><IcCalendar height={20} fill="black"/></Box>}
                bottomSheet={false}
                focus={isOpen}
                isDisabled={isDisabled}
            />

            <Box style={StyleSheet.absoluteFill} zIndex={100}>
                <DateTimePicker {...props} open={isOpen} onClose={onClose} onChange={onChange}/>
            </Box>
        </Pressable>
    );
};

export const DateTimeFieldNoLabel = (props: DateProps) => {
    const {value, type, isDisabled} = props;
    const {isOpen, onOpen, onClose} = useDisclose();

    const onChange = (date: Date) => {
        onClose();
        props.onChange(date);
    };

    return (
        <Pressable isDisabled={isDisabled} onPress={onOpen}>
            <FloatingLabelInput
                label=''
                isRequired
                value={format(value, datePickerFormat(type))}
                rightElement={<Box mr='4'><IcCalendar height={20} fill="black"/></Box>}
                bottomSheet={false}
                focus={isOpen}
                isDisabled={isDisabled}
            />

            <Box style={StyleSheet.absoluteFill} zIndex={100}>
                <DateTimePicker {...props} open={isOpen} onClose={onClose} onChange={onChange}/>
            </Box>
        </Pressable>
    );
};
