import * as React from 'react';
import Svg, {ClipPath, Defs, G, Path, SvgProps} from 'react-native-svg';

const IcChevronRight = (props: SvgProps) => (
    <Svg
        width={24}
        height={24}
        fill="none"
        viewBox='0 0 24 24'
        {...props}
    >
        <G clipPath="url(#ic_chevron_right)">
            <Path
                d="M10 6 8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6Z"
                fill={props.color ?? '#151515'}
            />
        </G>

        <Defs>
            <ClipPath id="ic_chevron_right">
                <Path fill="#fff" d="M0 0h24v24H0z" />
            </ClipPath>
        </Defs>
    </Svg>
);

export default IcChevronRight;
