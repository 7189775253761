import {Box, IBoxProps, Row} from 'native-base';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import React from 'react';

interface TopBarWrapperProps {
    children: React.ReactNode;
    left?: React.ReactNode;
    right?: React.ReactNode;
    fullWidth?: boolean;
    _row?: IHStackProps;
    _left?: IBoxProps;
    _center?: IBoxProps;
    _right?: IBoxProps;
}

export const TopBarWrapper = (props: TopBarWrapperProps) => {
    const {children, fullWidth = false, _row = {}, left, right, _left = {}, _center = {}, _right = {}} = props;

    return (
        <Row
            {..._row}
            w='full'
        >
            <Box
                flex={{
                    base: 0,
                    lg: fullWidth ? 0 : 1,
                }}
                {..._left}
            >
                {left}
            </Box>

            <Box
                flex={{
                    base: 1,
                    lg: fullWidth ? 1 : 8,
                }}
                {..._center}
            >
                {children}
            </Box>

            <Box
                flex={{
                    base: 0,
                    lg: fullWidth ? 0 : 1,
                }}
                {..._right}
            >
                {right}
            </Box>
        </Row>
    );
};
