import HeaderMenuItem from './HeaderMenuItem';
import {HeaderProps} from './HeaderProps';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const HeaderItemsLarge = (props: HeaderProps) => {
    const {selectedTab = true} = props;
    const {t} = useTranslation();

    return (
        <>
            <HeaderMenuItem
                title={t('clubhouse.title')}
                path='/clubhouse'
                isSelected={selectedTab === 'clubhouse'}
            />

            <HeaderMenuItem
                title={t('train.title')}
                path='/train'
                isSelected={selectedTab === 'train'}
            />

            <HeaderMenuItem
                title={t('fixture.list.title')}
                path='/matches'
                isSelected={selectedTab === 'fixtures'}
            />

            <HeaderMenuItem
                title={t('competition.list.title')}
                path='/competitions'
                isSelected={selectedTab === 'competitions'}
            />

            <HeaderMenuItem
                title={t('cms.shop.title')}
                path='/shop'
                isSelected={selectedTab === 'shop'}
            />
        </>
    );
};
