import {Button, Column, Text} from 'native-base';
import {Keyboard, Platform, TouchableWithoutFeedback} from 'react-native';
import React, {useState} from 'react';
import {EmailFormField} from '@ui/components/forms/Fields';
import useCrouton from '@ui/hooks/useCrouton';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';
import {validateEmail} from '@shared/helpers/string';

interface Props {
    onFinish: () => void;
}

export const ForgotPasswordScreen = (props: Props) => {
    const {onFinish} = props;
    const {t} = useTranslation();
    const [email, setEmail] = useState('');
    const crouton = useCrouton();
    const {resetPassword, isResetPasswordLoading} = useUser();

    const isValidEmail = validateEmail(email);

    const onPress = async() => {
        if(!isValidEmail) {
            return;
        }

        Keyboard.dismiss();

        const response = await resetPassword(email);

        if(response) {
            crouton.show({
                title: t('actions.success'),
                message: t('auth.forgotPassword.success'),
            });

            onFinish();
        }
    };

    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} disabled={Platform.OS === 'web'}>
            <Column
                flex={1}
                p='4'
                bg='white'
                rounded='xl'
                space='2'
                safeAreaBottom
            >
                <Text variant='h3'>
                    {t('auth.forgotPassword.subtitle')}
                </Text>

                <Text
                    color='secondary.600'
                    variant='bodyLg'
                >
                    {t('auth.forgotPassword.description')}
                </Text>

                <EmailFormField
                    defaultValue={email}
                    onChangeText={setEmail}
                    returnKeyType='done'
                    onSubmitEditing={onPress}
                    unstyled
                    autoFocus
                />

                <Button onPress={onPress} isDisabled={!isValidEmail} isLoading={isResetPasswordLoading}>
                    {t('auth.forgotPassword.button')}
                </Button>
            </Column>
        </TouchableWithoutFeedback>
    );
};
