import {track} from './analytics';

const useTrackButtonClick = () => {
    return (event_name: string, properties: unknown = {}, onPress?: () => void) => () => {
        track(event_name, properties);

        onPress?.();
    };
};

export default useTrackButtonClick;
