import {Column, Text} from 'native-base';
import QRCode from 'react-native-qrcode-svg';
import React from 'react';
import {useTranslation} from 'react-i18next';

const IOS_LINK = 'https://apps.apple.com/be/app/wamp-competitions/id6444795864';
const ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.wamp';

const DownloadAppStoreQRCodes = () => {
    const {t} = useTranslation();
    return (
        <Column rounded='xl' overflow="hidden" justifyContent="center">
            <Text variant='h5' w='full' color='white' bg='darkGreen' py='4' textAlign="center">{t('actions.downloadApp')}</Text>

            <Column bg='white' p='50' alignItems='center' space='1'>
                <QRCode
                    value={IOS_LINK}
                    size={95}
                    color='#E7C893'
                />

                <Text variant='mediumSemiBold'>{t('general.ios')}</Text>

                <Text variant='bodySmBold' color='blacks.400'>{t('general.appStore')}</Text>
            </Column>

            <Column bg='white' p='50' alignItems='center' space='1' borderTopWidth={1} borderTopColor="#ABABAB">
                <QRCode
                    value={ANDROID_LINK}
                    size={95}
                    color='#06331F'
                />

                <Text variant='mediumSemiBold'>{t('general.android')}</Text>

                <Text variant='bodySmBold' color='blacks.400'>{t('general.playStore')}</Text>
            </Column>
        </Column>
    );
};

export default DownloadAppStoreQRCodes;
