import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';
const IcDesktop = (props: SvgProps) => (
    <Svg
        width={116}
        height={116}
        fill="none"
        {...props}
    >
        <Path
            fill="#000"
            d="M90.9 11c-12.151 0-22 9.85-22 22s9.849 22 22 22c12.15 0 21.999-9.85 21.999-22s-9.849-22-22-22Zm-4.4 33.91L76.143 34.556l3.11-3.11 7.245 7.244 16.045-16.044 3.111 3.11-19.156 19.156Z"
        />

        <Path
            fill="#000"
            fillRule="evenodd"
            d="M64.83 17.961H11.8a8.798 8.798 0 0 0-8.8 8.8v52.8c0 4.862 3.938 8.8 8.8 8.8h30.8c0 4.862-3.938 8.8-8.8 8.8v8.8H69v-8.8a8.798 8.798 0 0 1-8.8-8.8H91c4.862 0 8.8-3.938 8.8-8.8V60.793a30.011 30.011 0 0 1-8.8 1.205v8.763H11.8v-44h50.005a29.791 29.791 0 0 1 3.025-8.8Zm-9.03 61.6c0-2.428-1.971-4.4-4.4-4.4a4.402 4.402 0 0 0-4.4 4.4c0 2.43 1.971 4.4 4.4 4.4 2.429 0 4.4-1.97 4.4-4.4Z"
            clipRule="evenodd"
        />
    </Svg>
);
export default IcDesktop;
