import {Box, IBoxProps} from 'native-base';
import {ImageProps, Image as SVGImage} from 'react-native-svg';
import React from 'react';
import {SolitoImage} from 'solito/image';

const DivotImage = (props: IBoxProps) => {
    return (
        <Box h='170' w='170' {...props}>
            <SolitoImage
                fill
                src='/img/wallet/divot_image.png'
                alt='harold-bg'
                contentFit='contain'
                contentPosition='left bottom'
            />
        </Box>
    );
};

export const DivotImageSvg = (props: ImageProps) => {
    return (
        <SVGImage
            href={{uri: '/img/wallet/divot_image.png'}}
            {...props}
        />
    );
};

export default DivotImage;
