import {Pressable, Row, Text} from 'native-base';
import IcChevronRight from '@ui/svg/IcChevronRight';
import React from 'react';
import {useRouter} from 'solito/router';
import {useTranslation} from 'react-i18next';

const flip = {transform: [{rotate: '180deg'}]};

interface Props {
    title?: string;
    _right?: JSX.Element;
    _center?: JSX.Element;
    onBack?: () => void;
}

const DashboardBackButton = (props: Props) => {
    const {t} = useTranslation();
    const {back} = useRouter();
    const {title = t('dashboard.header.back'), _right, _center, onBack = back} = props;

    return (
        <Pressable onPress={onBack}>
            <Row py='4' pr='2' alignItems="center">
                <IcChevronRight style={flip}/>

                <Row space='4' flex={1} alignItems='center'>
                    <Text variant='mediumBody'>
                        {title}
                    </Text>

                    {_center}
                </Row>

                {_right}
            </Row>
        </Pressable>
    );
};

export default DashboardBackButton;
