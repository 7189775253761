import {
    Menu,
    Row, Text,
} from 'native-base';
import {HeaderProps} from './HeaderProps';
import {HeaderSelectMenuItem} from './HeaderMenuItem';
import IcBurger from '@ui/svg/IcBurger';
import React from 'react';
import {TouchableOpacity} from 'react-native';
import {useTranslation} from 'react-i18next';

export const HeaderItemsSmall = (props: HeaderProps) => {
    const {selectedTab} = props;
    const {t} = useTranslation();

    const menuTitle = () => {
        switch (selectedTab) {
            case 'competitions':
                return t('competition.list.title');
            case 'fixtures':
                return t('fixture.list.title');
            case 'train':
                return t('train.title');
            case 'clubhouse':
                return t('clubhouse.title');
            case 'shop':
                return t('cms.shop.title');
            default:
                return '';
        }
    };

    return (
        <Menu
            trigger={triggerProps => {
                return <TouchableOpacity {...triggerProps}>
                    <Row space={2}>
                        <IcBurger width={24} height={24} />

                        <Text
                            variant='medium'
                        >
                            {menuTitle()}
                        </Text>
                    </Row>
                </TouchableOpacity>;
            }}
        >
            <HeaderSelectMenuItem
                title={t('clubhouse.title')}
                path='/clubhouse'
                isSelected={selectedTab === 'clubhouse'}
            />

            <HeaderSelectMenuItem
                title={t('train.title')}
                path='/train'
                isSelected={selectedTab === 'train'}
            />

            <HeaderSelectMenuItem
                title={t('fixture.list.title')}
                path='/matches'
                isSelected={selectedTab === 'fixtures'}
            />

            <HeaderSelectMenuItem
                title={t('competition.list.title')}
                path='/competitions'
                isSelected={selectedTab === 'competitions'}
            />

            <HeaderSelectMenuItem
                title={t('cms.shop.title')}
                path='/shop'
                isSelected={selectedTab === 'shop'}
            />
        </Menu>
    );
};
