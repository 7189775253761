import {DivotsImageLabel} from './DivotsLabel';
import NavigationLink from '@ui/components/buttons/NavigationLink';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import {useUserWallet} from '@ui/provider/wallet';

const UserDivotsLabel = () => {
    const {wallet} = useUserWallet();

    if(!wallet) {
        return null;
    }

    return (
        <NavigationLink path={getScreen('wallet')}>
            <DivotsImageLabel divots={wallet.balance.toFixed(0)} />
        </NavigationLink>
    );
};

export default UserDivotsLabel;
