import React, {useEffect, useState} from 'react';
import BottomsheetProps from '@ui/components/sheet/BottomsheetProps';
import BottomsheetWamp from '@ui/components/sheet/Bottomsheet';
import {Box} from 'native-base';
import CarouselFadeInView from './CarouselFadeInView';
import {BottomSheetProps as GorhomBottomSheetProps} from '@gorhom/bottom-sheet';
import {get} from 'lodash';
import {useWindowDimensions} from 'react-native';

interface Props extends Omit<BottomsheetProps, 'children'> {
    titles?: string[];
    children: JSX.Element[];
    page: number;
    onBack?: () => void;
    snapPoints?: string[];
    _bottomSheetProps?: Partial<GorhomBottomSheetProps>;
}

const CarouselBottomsheet = (props: Props) => {
    const {isOpen, onClose, titles, page, onBack, snapPoints, _bottomSheetProps} = props;
    const {width} = useWindowDimensions();
    const [viewWidth, setViewWidth] = useState(0);
    const [[index, direction], setState] = useState([0, 0]);

    useEffect(() => {
        if (isOpen) {
            setState([0, 0]);
        }
    }, [isOpen]);

    useEffect(() => {
        setState([page, 0]);
    }, [page]);

    const onCloseHandler = () => {
        onClose();
        setState([0, 0]);
    };

    const title = get(titles, index, '');
    const snapPoint = get(snapPoints, index, '90%');

    return (
        <BottomsheetWamp
            isOpen={isOpen}
            onClose={onCloseHandler}
            _bottomSheetProps={{snapPoints: [snapPoint], ..._bottomSheetProps}}
            title={title}
            onBack={onBack}
        >
            <Box flex={1} overflow='hidden' onLayout={(e) => setViewWidth(e.nativeEvent.layout.width)}>
                <CarouselFadeInView direction={direction} width={viewWidth ?? width} index={index}>
                    {props.children}
                </CarouselFadeInView>
            </Box>
        </BottomsheetWamp>
    );
};

export default CarouselBottomsheet;
