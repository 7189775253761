import {
    Button,
    Checkbox,
    Column,
    Pressable,
    Row,
    ScrollView,
    Text,
} from 'native-base';
import {EmailFormField, PasswordFormField} from '@ui/components/forms/Fields';
import {Keyboard, Linking, Platform, TextInput, TouchableWithoutFeedback} from 'react-native';
import React, {useEffect, useState} from 'react';
import {firstNameValidations, lastNameValidations} from '@ui/components/forms/validation';
import FloatingLabelInput from '@ui/components/FloatingLabelInput';
import {TERMS_CONDITIONS_URL} from '@ui/helpers/constants';
import {baseHostname} from '@ui/helpers/url';
import useCrouton from '@ui/hooks/useCrouton';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface FormProps {
    onFinish: () => void;
    bottomSheet?: boolean;
    email?: string;
}

export const RegisterForm = (props: FormProps) => {
    const {onFinish, bottomSheet = false} = props;
    const {t} = useTranslation();
    const crouton = useCrouton();

    const [email, setEmail] = useState(props.email ?? '');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPass] = useState('');
    const [showPass, setShowPass] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);

    const emailRef = React.useRef<TextInput>();
    const firstNameRef = React.useRef<TextInput>();
    const lastNameRef = React.useRef<TextInput>();
    const passwordRef = React.useRef<TextInput>();
    const repeatPasswordRef = React.useRef<TextInput>();

    const isButtonDisabled = [email, firstName, lastName, password].find(x => x.trim().length === 0) !== undefined || !acceptTerms;

    const {registerWithEmailAndPassword, isRegisterLoading, isUserLoading, isLoggedIn} = useUser();
    const isLoading = isRegisterLoading || isUserLoading;

    useEffect(() => {
        if(isLoggedIn) {
            onFinish();
        }
    }, [isLoggedIn]);

    const onPressRegister = async() => {
        Keyboard.dismiss();

        if(isButtonDisabled) {
            return;
        }

        const validations = [
            ...firstNameValidations(firstName, t),
            ...lastNameValidations(lastName, t)].filter(x => x !== undefined);

        if(validations.length > 0) {
            crouton.show({
                title: t('actions.warning'),
                message: validations.join('\n'),
                preset: 'error',
            });

            return;
        }

        const response = await registerWithEmailAndPassword(
            email,
            password,
            {firstName, lastName, username: `${firstName} ${lastName}`},
        );
        if(!response) {
            return;
        }
    };

    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} disabled={Platform.OS === 'web'}>
            <ScrollView>
                <Column
                    space={4}
                    flex={1}
                    padding={4}
                    rounded="xl"
                    bg='white'
                    p='4'
                    justifyContent="space-between"
                    safeAreaBottom
                >
                    <Column space={2}>
                        <Text variant='h4'>
                            {t('auth.signUp.title')}
                        </Text>

                        <Text variant='bodyLg' color='secondary.500'>
                            {t('auth.signUp.subtitle')}
                        </Text>
                    </Column>

                    <Column space={4} w='full'>
                        <FloatingLabelInput
                            isRequired
                            label={t('auth.firstName.text')}
                            defaultValue={firstName}
                            onChangeText={setFirstName}
                            textContentType='name'
                            keyboardType='name-phone-pad'
                            autoCorrect={false}
                            autoComplete='name'
                            returnKeyType='next'
                            maxLength={60}
                            onSubmitEditing={() => lastNameRef.current?.focus()}
                            blurOnSubmit={false}
                            inputRef={firstNameRef}
                            bottomSheet={bottomSheet}
                            autoFocus
                        />

                        <FloatingLabelInput
                            isRequired
                            label={t('auth.lastName.text')}
                            defaultValue={lastName}
                            onChangeText={setLastName}
                            textContentType='familyName'
                            keyboardType='name-phone-pad'
                            autoCorrect={false}
                            autoComplete='name-family'
                            returnKeyType='next'
                            maxLength={60}
                            onSubmitEditing={() => emailRef.current?.focus()}
                            blurOnSubmit={false}
                            inputRef={lastNameRef}
                            bottomSheet={bottomSheet}
                        />

                        <EmailFormField
                            bottomSheet={bottomSheet}
                            defaultValue={email}
                            onChangeText={setEmail}
                            inputRef={emailRef}
                            onSubmitEditing={() => passwordRef.current?.focus()}
                        />

                        <PasswordFormField
                            defaultValue={password}
                            onChangeText={setPass}
                            inputRef={passwordRef}
                            onSubmitEditing={() => repeatPasswordRef.current?.focus()}
                            showPass={showPass}
                            setShowPass={setShowPass}
                            bottomSheet={bottomSheet}
                        />

                        <Checkbox
                            alignItems='flex-start'
                            value='acceptTerms'
                            isChecked={acceptTerms}
                            onChange={v => setAcceptTerms(v)}
                            accessibilityLabel={t('auth.signUp.accept.accessabilityLabel')}
                            _checked={{
                                bg: 'black',
                                borderColor: 'black',
                                _pressed: {
                                    bg: 'secondary.800',
                                    borderColor: 'secondary.700',
                                },
                            }}
                            _focus={{
                                borderColor: 'black',
                            }}
                        >
                            <Row alignItems='center'>
                                <Text variant='body' pl='2'>
                                    {t('auth.signUp.accept.text')}{' '}
                                </Text>

                                <Pressable onPress={() => Linking.openURL(`https://${baseHostname()}/${TERMS_CONDITIONS_URL}`)}>
                                    <Text
                                        variant='body'
                                        fontWeight={600}
                                    >
                                        {t('auth.signUp.termsOfUse')}
                                    </Text>
                                </Pressable>
                            </Row>
                        </Checkbox>
                    </Column>

                    <Column justifyContent='flex-end'>

                        <Button
                            size='md'
                            onPress={onPressRegister}
                            isDisabled={isButtonDisabled}
                            isLoading={isLoading}
                        >
                            {t('auth.signUp.register')}
                        </Button>
                    </Column>
                </Column>
            </ScrollView>
        </TouchableWithoutFeedback>
    );
};
