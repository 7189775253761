import {
    Button,
    Row,
    useBreakpointValue,
    useDisclose,
} from 'native-base';
import CurrentUserParticipantAvatar from '@ui/components/participant/CurrentUserParticipantAvatar';
import LoginModal from '@ui/features/auth/login/LoginModal';
import {PresenceLoadingScreen} from '@ui/components/LoadingScreen';
import React from 'react';
import UserDivotsLabel from '@ui/features/wallet/UserDivotsLabel';
import {useRouter} from 'solito/router';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface ButtonProps {
    onOpenLoginRegister: () => void;
}

const AuthButtons = (props: ButtonProps) => {
    const {onOpenLoginRegister} = props;
    const {isLoggedIn, isLoading, user} = useUser();
    const {t} = useTranslation();

    const hasUser = isLoggedIn && user;

    const loginText = useBreakpointValue({
        base: t('auth.login'),
        lg: t('auth.loginRegister'),
    });

    return (
        <PresenceLoadingScreen isLoading={isLoading} showSpinner={false}>
            <Row
                space='2'
                flex={1}
                justifyContent={{
                    base: 'flex-end',
                    lg: isLoggedIn ? 'center' : 'flex-start',
                }}
                pr='4'
                alignItems="center"
            >
                {hasUser ? (
                    <>
                        <UserDivotsLabel />

                        <CurrentUserParticipantAvatar />
                    </>
                ) : (
                    <Button variant='primary' onPress={onOpenLoginRegister}>
                        {loginText}
                    </Button>
                )}
            </Row>
        </PresenceLoadingScreen>
    );
};

const AuthHeader = () => {
    const {replace} = useRouter();
    const {isOpen: isLoginOpen, onOpen: onOpenLogin, onClose: onCloseLogin} = useDisclose();

    const onFinish = () => {
        onCloseLogin();
        replace({
            pathname: '/',
            query: {
                showWelcome: 'true',
            },
        });
    };

    return (
        <>
            <AuthButtons onOpenLoginRegister={onOpenLogin} />

            <LoginModal isOpen={isLoginOpen} onClose={onCloseLogin} onFinish={onFinish} />
        </>
    );
};

export default React.memo(AuthHeader);
