import {Column} from 'native-base';
import React from 'react';

interface MainContentProps {
    children?: JSX.Element | JSX.Element[];
}

const MainContent = (props: MainContentProps) => {
    return (
        <Column flex={1} width="full" >
            {props.children}
        </Column>
    );
};

export default MainContent;
