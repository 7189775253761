import {Box, Button, Center, Column} from 'native-base';
import {DownloadAppStoreButtonRow} from './DownloadAppStoreButtonRow';
import IcWAMP from '@ui/svg/IcWAMP';
import {IcWampGolf} from './IcWampGolf';
import React from 'react';
import {StyleSheet} from 'react-native';
import WAMPImage from '../image/WAMPImage';
import {useDashboardAssets} from '@ui/features/clubhouse/hooks/dashboard-hooks';
import {useTranslation} from 'react-i18next';

const BUNDLE_ID = process.env.NEXT_PUBLIC_APPLE_BUNDLE_ID;

const isMobileBrowser = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

const DownloadAppSmallScreen = () => {
    const [dashboardConfig] = useDashboardAssets();
    const image = dashboardConfig?.login?.clubhouse?.image;

    const {t} = useTranslation();

    if (typeof window === 'undefined') {
        return undefined;
    }
    const intent = `${BUNDLE_ID}:/${window.location.pathname}`;

    return (
        <Box flex={1}>
            {image &&
                <WAMPImage
                    src={image?.xl}
                    image={image}
                    fill
                    alt='welcome-bg'
                    contentFit='cover'
                    style={StyleSheet.absoluteFillObject}
                />
            }

            <Column alignItems="center" flex={1} px='4'>
                <Column flex={1} space={2} justifyContent="center">
                    <Center rounded='xl' bg='lime.default' w={100} h={100} >
                        <IcWAMP fill='black' width={68} height={37}/>
                    </Center>

                    <IcWampGolf />
                </Column>

                <Column flex={1} space='9'>
                    <DownloadAppStoreButtonRow _button={{variant: 'primary', colorScheme: 'black'}}/>

                    {isMobileBrowser() &&
                    <Button variant="lime" onPress={() => window.location.replace(intent)}>
                        {t('actions.openInApp')}
                    </Button>
                    }
                </Column>
            </Column>
        </Box>
    );
};

export default DownloadAppSmallScreen;
