const passwordValidations = (password: string, repeatPassword: string, t: (key: string, vals?: unknown) => string) => {
    const errors: string[] = [];

    const trimmedPassword = password.trim();
    const trimmedRepeatPassword = repeatPassword.trim();

    if(password === '') {
        errors.push(t('auth.signUp.passwordsEmpty'));
    }

    if(trimmedPassword !== trimmedRepeatPassword) {
        errors.push(t('auth.signUp.passwordsNotMatch'));
    }

    if(password.length < 6) {
        errors.push(t('auth.signUp.passwordsTooShort', {minLength: 6}));
    }

    return errors;
};

// Length of string should be between numbers
const isBetween = (str: string, minLength: number, maxLength: number) => {
    return str.trim().length >= minLength && str.trim().length <= maxLength;
};

// Disalow numbers
const hasNumbers = (str: string) => {
    return /\d/.test(str);
};

const firstNameValidations = (name: string, t: (v: string, params?: unknown) => string) => {
    if (!isBetween(name, 2, 50)) {
        return [t('auth.signUp.firstNameIncorrectLength', {minLength: 2, maxLength: 50})];
    }

    if (hasNumbers(name)) {
        return [t('auth.signUp.firstNameContainsNumbers')];
    }

    return [];
};


const lastNameValidations = (name: string, t: (v: string, params?: unknown) => string) => {
    const errors = [];
    if (!isBetween(name, 2, 50)) {
        errors.push(t('auth.signUp.lastNameIncorrectLength', {minLength: 2, maxLength: 50}));
    }

    if (hasNumbers(name)) {
        errors.push(t('auth.signUp.lastNameContainsNumbers'));
    }

    return errors;
};

const phoneValidations = (phone: string, t: (v: string, params?: unknown) => string) => {
    if (phone.length !== 0 && !isBetween(phone, 6, 16)) {
        return [t('auth.signUp.phoneIncorrectLength', {minLength: 6, maxLength: 16})];
    }

    return [];
};

export {passwordValidations, firstNameValidations, lastNameValidations, phoneValidations};
