import {Box, Column, Row, Text} from 'native-base';
import IcArrowForward from '@ui/svg/IcArrowForward';
import IcDesktop from '@ui/svg/IcDesktop';
import IcPhoneCrossed from '@ui/svg/IcPhoneCrossed';
import NavigationButton from './buttons/NavigationButton';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import {useTranslation} from 'react-i18next';

interface Props {
    message?: string;
}

const ScreenSizeWarning = (props: Props) => {
    const {message} = props;
    const {t} = useTranslation();

    return (
        <Column flex={1} justifyContent='center' alignItems='center' p={4} safeAreaBottom space='6'>
            <Box flex={1} justifyContent="flex-end">
                <Row space='4' alignItems='center' justifyContent="space-around" alignContent="flex-end">
                    <IcPhoneCrossed />

                    <IcArrowForward width={40} height={40} />

                    <IcDesktop />
                </Row>
            </Box>

            <Column space='6' flex={1} justifyContent='center' alignItems='center'>
                <Text variant='h5' textAlign='center' maxW={600}>{message ?? t('screenSizeWarning.text')}</Text>

                <Text variant='bodyLg' textAlign='center' maxW={600}>{t('screenSizeWarning.tablet')}</Text>
            </Column>

            <Box flex={1} w='full' justifyContent="flex-end">
                <NavigationButton path={getScreen('clubhouse')} w='full'>
                    {t('general.goHome')}
                </NavigationButton>
            </Box>
        </Column>
    );
};

export default ScreenSizeWarning;
