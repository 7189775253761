import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const IcOldWamp = (props: SvgProps) => (
    <Svg
        viewBox='0 0 89 64'
        width={89}
        height={64}
        fill="white"
        {...props}
    >
        <Path d="M11.4958 22.7445C17.8448 22.7445 22.9917 17.6529 22.9917 11.3722C22.9917 5.09152 17.8448 0 11.4958 0C5.14686 0 0 5.09152 0 11.3722C0 17.6529 5.14686 22.7445 11.4958 22.7445ZM44.5782 6.86646e-05C40.0379 6.86646e-05 35.9215 2.67858 34.0611 6.84163L13.5108 52.846C11.3208 57.7468 14.8889 63.289 20.2335 63.289H27.4402C31.5026 63.289 35.189 60.8905 36.8533 57.1656L55.0953 16.3346C58.5196 8.66644 52.9382 6.86646e-05 44.5782 6.86646e-05ZM77.4582 6.86646e-05C72.9195 6.86646e-05 68.8017 2.67858 66.9428 6.84163L46.3913 52.846C44.2014 57.7468 47.7694 63.289 53.1155 63.289H60.322C64.3844 63.289 68.0692 60.8905 69.7349 57.1656L87.9751 16.3346C91.4009 8.66644 85.8196 6.86646e-05 77.4582 6.86646e-05Z" />
    </Svg>
);
export default IcOldWamp;
