import {IScrollViewProps, ScrollView} from 'native-base';
import {ScrollView as RNScrollView} from 'react-native';
import React from 'react';

const contentContainerStyle = {flexGrow: 1};

interface Props extends IScrollViewProps {
    _scrollRef?: React.RefObject<RNScrollView>;
}

const ScrollViewWrapper = (props: Props) => {
    return (
        <ScrollView
            flex={1}
            contentContainerStyle={contentContainerStyle}
            showsVerticalScrollIndicator={false}
            h='full'
            ref={props._scrollRef}
            {...props}
        />
    );
};

export default ScrollViewWrapper;
