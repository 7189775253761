import * as React from 'react';
import Svg, {Path, SvgProps} from 'react-native-svg';

const IcGoogleLogo = (props: SvgProps) => (
    <Svg
        width={21}
        height={20}
        viewBox="0 0 21 20"
        fill="none"
        {...props}
    >
        <Path
            d="M18.673 8.367H18v-.034h-7.5v3.333h4.71A4.998 4.998 0 015.5 9.999a5 5 0 015-5c1.275 0 2.434.481 3.317 1.267l2.357-2.357a8.295 8.295 0 00-5.674-2.243 8.334 8.334 0 108.172 6.701z"
            fill="#FFC107"
        />

        <Path
            d="M3.129 6.12L5.867 8.13a4.998 4.998 0 014.634-3.13c1.275 0 2.435.481 3.318 1.267l2.357-2.357A8.295 8.295 0 0010.5 1.666 8.329 8.329 0 003.13 6.121z"
            fill="#FF3D00"
        />

        <Path
            d="M10.5 18.334a8.294 8.294 0 005.588-2.164l-2.58-2.182A4.963 4.963 0 0110.502 15 4.998 4.998 0 015.8 11.69l-2.718 2.093a8.327 8.327 0 007.419 4.55z"
            fill="#4CAF50"
        />

        <Path
            d="M18.671 8.369H18v-.035h-7.5v3.333h4.71a5.017 5.017 0 01-1.703 2.322v-.001l2.58 2.182c-.182.166 2.746-2.003 2.746-6.17 0-.558-.057-1.104-.162-1.631z"
            fill="#1976D2"
        />
    </Svg>
);

export default IcGoogleLogo;
