import './date-time-picker.css';
import {DateTimePickerProps, datePickerFormat} from './DatePickerProps';
import DatePicker from 'react-datepicker';
import React from 'react';

const TimeInput = ({value, onChange}: any) => {
    return (
        <input
            value={value}
            type="time"
            onChange={(e) => {
                if (e.target.value) {
                    onChange(e.target.value);
                }
            }}
        />
    );
};
export const DateTimePicker = (props: DateTimePickerProps) => {
    const {value, type, onChange, maximumDate, minimumDate, disabled, open, onClose} = props;
    return (
        <DatePicker
            selected={value}
            disabled={disabled}
            onChange={(date: any) => onChange(date)}
            showTimeSelectOnly={type === 'time'}
            showYearDropdown
            maxDate={maximumDate}
            minDate={minimumDate}
            customTimeInput={<TimeInput />}
            showTimeInput={type !== 'date'}
            onCalendarClose={onClose}
            open={open}
            onBlur={onClose}
            onClickOutside={onClose}
            dateFormat={datePickerFormat(type)}
            portalId='datepicker-portal'
        />
    );
};

export default DateTimePicker;
