import * as React from 'react';
import {ANDROID_LINK, IOS_LINK} from './AppStoreConsts';
import {IButtonProps, Row} from 'native-base';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import {IcDownloadAndroid} from './IcDownloadAndroid';
import {IcDownloadIos} from './IcDownloadIos';
import NavigationButton from '../buttons/NavigationButton';

interface Props extends IHStackProps {
    _button?: IButtonProps;
}

export const DownloadAppStoreButtonRow = (props: Props) => {
    const {_button, ...rest} = props;

    return (
        <Row space='2' w='full' minW={270} alignItems="center" {...rest}>
            <NavigationButton
                path={IOS_LINK}
                variant='ghostOutline'
                flex={1}
                h='14'
                leftIcon={<IcDownloadIos />}
                borderColor="blacks.900"
                {..._button}
            />

            <NavigationButton
                path={ANDROID_LINK}
                variant='ghostOutline'
                flex={1}
                h='14'
                leftIcon={<IcDownloadAndroid />}
                borderColor="blacks.900"
                {..._button}
            />
        </Row>
    );
};
