import {Box, Button, Column, Divider, Row, Text} from 'native-base';
import {Platform, TextInput} from 'react-native';
import React, {useEffect} from 'react';
import AppleLoginButton from './login/AppleLoginButton';
import AuthInfoCard from './components/AuthInfoCard';
import BottomSheetScrollView from '@ui/components/gorhom/BottomSheetScrollView';
import {EmailFormField} from '@ui/components/forms/Fields';
import GoogleLoginButton from './login/GoogleLoginButton';
import {useTranslation} from 'react-i18next';
import {useUser} from '@ui/provider/user/UserProvider';

interface Props {
    emailButtonText: string;
    googleButtonText: string;
    appleButtonText: string;
    onPressEmail: () => void;
    isEmailLoading?: boolean;
    onFinish: (newUser?: boolean) => void;
    title: string;
    subtitle: string;

    email?: string;
    setEmail?: (email: string) => void;
}

const AuthMethodSelectScreen = (props: Props) => {
    const {googleButtonText, appleButtonText, onPressEmail, email, setEmail, isEmailLoading = false, onFinish} = props;
    const {isLoggedIn} = useUser();

    const {t} = useTranslation();
    const emailRef = React.useRef<TextInput>();

    useEffect(() => {
        if(isLoggedIn) {
            onFinish();
        }
    }, [isLoggedIn]);

    return (
        <BottomSheetScrollView>
            <Column flex={1} p='4' bg='white' rounded='3xl' overflow="hidden">
                <Text variant='h4'>
                    {props.title}
                </Text>

                <Text variant='body'>
                    {props.subtitle}
                </Text>

                <Box h={7} />

                <Column space='4' flex={1}>
                    <AuthInfoCard />

                    <Box minH={10}>
                        <EmailFormField
                            bottomSheet
                            defaultValue={email}
                            onChangeText={setEmail}
                            inputRef={emailRef}
                            onSubmitEditing={onPressEmail}
                        />
                    </Box>

                    <Button
                        size='md'
                        onPress={onPressEmail}
                        isLoading={isEmailLoading}
                    >
                        {t('actions.continue')}
                    </Button>

                    <Column space='4'>
                        <Row alignItems='center' space='2'>
                            <Divider flex={1} bg='blacks.400'/>

                            <Text variant='body' color='blacks.400'>
                                {t('auth.orContinueWith')}
                            </Text>

                            <Divider flex={1} bg='blacks.400'/>
                        </Row>


                        {Platform.OS !== 'android' &&
                    <AppleLoginButton text={appleButtonText} />
                        }

                        <GoogleLoginButton text={googleButtonText}/>
                    </Column>
                </Column>
            </Column>
        </BottomSheetScrollView>
    );
};

interface SignInProps {
    onPressEmail: () => void;
    onFinish: (newUser?: boolean) => void;
    email?: string;
    setEmail?: (email: string) => void;
    isEmailLoading?: boolean;
}

const AuthMethodSelectScreenSignIn = (props: SignInProps) => {
    const {onPressEmail, onFinish, email, setEmail, isEmailLoading} = props;
    const {t} = useTranslation();

    return (
        <AuthMethodSelectScreen
            emailButtonText={t('auth.signIn.signInWithEmail')}
            googleButtonText={t('auth.signIn.signInWithGoogle')}
            appleButtonText={t('auth.signIn.signInWithApple')}
            onPressEmail={onPressEmail}
            onFinish={onFinish}
            title={t('auth.signUp.welcomeTitle')}
            subtitle={t('auth.signUp.welcomeSubtitle')}
            email={email}
            setEmail={setEmail}
            isEmailLoading={isEmailLoading}
        />
    );
};

interface RegisterProps {
    onPressEmail: () => void;
    onFinish: (newUser?: boolean) => void;
}

const AuthMethodSelectScreenRegister = (props: RegisterProps) => {
    const {onPressEmail, onFinish} = props;
    const {t} = useTranslation();

    return (
        <AuthMethodSelectScreen
            emailButtonText={t('auth.signUp.signUpWithEmail')}
            googleButtonText={t('auth.signUp.signUpWithGoogle')}
            appleButtonText={t('auth.signUp.signUpWithApple')}
            onPressEmail={onPressEmail}
            onFinish={onFinish}
            title={t('auth.signUp.welcomeTitle')}
            subtitle={t('auth.signUp.welcomeSubtitle')}
        />
    );
};

export {AuthMethodSelectScreenSignIn, AuthMethodSelectScreenRegister};

export default AuthMethodSelectScreen;
