import {Button, IButtonProps} from 'native-base';
import React from 'react';
import {UrlObject} from 'url';
import {useLink} from 'solito/link';

interface NavigationButtonProps extends IButtonProps {
    path: string | UrlObject;
}

const NavigationButton = (props: NavigationButtonProps) => {
    const {children, path, onPress: buttonOnPress, ...restProps} = props;

    const {onPress: linkOnPress, ...restLinkProps} = useLink({
        href: path,
    });

    return (
        <Button
            {...restProps}
            {...restLinkProps}
            onPress={(e) => {
                buttonOnPress?.(e);
                linkOnPress();
            }}
        >
            {children}
        </Button>
    );
};

export default NavigationButton;
