import {Row, Text} from 'native-base';
import DivotImage from './DivotImage';
import {IHStackProps} from 'native-base/lib/typescript/components/primitives/Stack/HStack';
import LimeLabel from '@ui/components/label/LimeLabel';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface Props extends IHStackProps {
    divots: number | string;
}

const DivotsLabel = (props: Props) => {
    const {divots} = props;
    const {t} = useTranslation();
    const text = `${divots} ${t('wallet.divots')}`;
    return (
        <LimeLabel>{text}</LimeLabel>
    );
};

export const DivotsImageLabel = (props: Props) => {
    const {divots, ...rest} = props;

    return (
        <Row space='1' rounded='2xl' bg='darkGreen' p='2px' alignItems="center" pr='3' {...rest}>
            <DivotImage h={5} w={5} />

            <Text variant='bodySmBold' color='white'>{divots}</Text>
        </Row>
    );
};

export default DivotsLabel;
