import Svg, { Path, Rect, SvgProps } from 'react-native-svg';
import React from 'react';

export const IcWampGolf = (props: SvgProps) => (
    <Svg
        width={109}
        height={36}
        fill="none"
        {...props}
    >
        <Rect
            width={107.774}
            height={35.348}
            x={0.253}
            y={0.253}
            stroke="#CFFE80"
            strokeWidth={0.506}
            rx={7.846} />

        <Path
            fill="#CFFE80"
            d="M88.459 9.146h6.153v3.017h-2.775v3.957h2.654v3.016h-2.654v6.901H88.46V9.147ZM81.742 9.146h3.378v13.875h2.437v3.016h-5.815V9.147ZM80.572 23.045c0 2.091-1.045 3.137-3.137 3.137h-2.171c-2.091 0-3.137-1.046-3.137-3.137V12.14c0-2.091 1.046-3.137 3.137-3.137h2.171c2.092 0 3.137 1.046 3.137 3.137v10.906Zm-3.378-10.544c0-.402-.201-.603-.603-.603h-.483c-.402 0-.603.2-.603.603v10.182c0 .402.201.604.603.604h.483c.402 0 .603-.202.603-.604V12.501ZM67.7 19.33h-1.062v-2.824h4.368v6.54c0 2.09-1.046 3.136-3.137 3.136h-2.172c-2.09 0-3.137-1.046-3.137-3.137V12.14c0-2.091 1.046-3.137 3.137-3.137h2.124c2.09 0 3.136 1.046 3.136 3.137v3.233h-3.33v-2.871c0-.402-.2-.603-.602-.603h-.483c-.402 0-.603.2-.603.603v10.182c0 .402.2.604.603.604h.555c.402 0 .603-.202.603-.604V19.33ZM53.687 20.174v5.863h-3.378V9.147h4.946c2.091 0 3.137 1.045 3.137 3.136v4.754c0 2.091-1.046 3.137-3.137 3.137h-1.568Zm1.327-7.529c0-.402-.201-.603-.603-.603h-.724v5.236h.724c.402 0 .603-.201.603-.603v-4.03ZM42.198 9.146l.99 10.207L44.2 9.146H49.1v16.891h-3.33V14.962l-1.278 11.075h-3.185l-1.28-11.196v11.196h-2.702V9.147h4.874ZM34.387 9.146l2.22 16.891h-3.354l-.265-2.461h-2.124l-.241 2.461h-3.064l2.22-16.89h4.608Zm-2.461 4.102-.748 7.384h1.52l-.772-7.384ZM16.768 26.037l-2.051-16.89h3.426l.869 9 .965-9h3.185l.965 8.976.869-8.977h2.992l-2.051 16.891H22.22l-.869-7.673-.868 7.673h-3.716Z" />
    </Svg>
);
