import {ScrollView, ScrollViewProps, StyleSheet} from 'react-native';
import React from 'react';

const BottomSheetScrollView = (props: ScrollViewProps) => {
    return (
        <ScrollView
            style={StyleSheet.absoluteFill}
            {...props}
        />
    );
};

export default BottomSheetScrollView;
