import {IStackProps, Stack} from 'native-base';
import {MotiLink} from 'solito/moti';
import React from 'react';
import {UrlObject} from 'url';

export interface NavigationLinkProps extends IStackProps {
    children: JSX.Element | JSX.Element[];
    path: string | UrlObject;
    hover?: number;
    style?: any;
    onPress?: () => void;
    isCard?: boolean;
}

const NavigationLink = (props: NavigationLinkProps) => {
    const {children, path, hover = 0.8, style = {}, onPress, isCard = false, ...restProps} = props;

    return (
        <MotiLink
            href={path}
            animate={({hovered, pressed}) => {
                'worklet';
                return {
                    opacity: pressed ? 0.7 : hovered ? hover : 1,
                    scale: pressed ? isCard ? 0.97 : 1 : 1,
                };
            }}
            disabled={restProps.isDisabled}
            style={style}
            onPress={onPress}
        >
            <Stack {...restProps}>
                {children}
            </Stack>
        </MotiLink>
    );
};

export default NavigationLink;
