import {Box, IBoxProps, ITextProps, Text} from 'native-base';
import React from 'react';

interface Props extends ITextProps{
    _wrapper?: IBoxProps;
}

const LimeLabel = (props:Props) => {
    const {_wrapper, ...rest} = props;
    return (
        <Box
            rounded='lg'
            bg='lime.default'
            px='2'
            py='1'
            {..._wrapper}
        >
            <Text variant='bodySm' color='black' fontWeight={500} {...rest} />
        </Box>
    );
};

export default LimeLabel;
