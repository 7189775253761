import Animated, {Extrapolation, interpolate, useAnimatedStyle, useSharedValue, withTiming} from 'react-native-reanimated';
import {Box, IBoxProps, useTheme} from 'native-base';
import React, {useEffect} from 'react';
import {Platform} from 'react-native';

export interface FloatingLabelProps {
    label: string;
    labelColor?: string;
    labelBGColor?: string;
    children?: React.ReactNode;
    isFocused?: boolean;
    hasValue?: boolean;
    isRequired?: boolean;
    _wrapper?: IBoxProps;
    isVisible?: boolean;
    labelPosition?: 'left' | 'right';
}

const FloatingLabelView = (props: FloatingLabelProps) => {
    const theme = useTheme();
    const {isFocused, hasValue, label, isRequired, labelColor = theme.colors.blacks[400], labelBGColor = 'white', _wrapper, labelPosition = 'left', isVisible = true} = props;

    const animatedIsFocusedValue = useSharedValue(0);

    useEffect(() => {
        const shouldAnimate = isFocused || hasValue;
        animatedIsFocusedValue.value = withTiming(shouldAnimate ? 1 : 0, {
            duration: 200,
        });
    }, [isFocused, hasValue]);

    const labelContainerStyle = useAnimatedStyle(() => {
        const top = interpolate(animatedIsFocusedValue.value, [0, 1], [12, -12]);

        return {
            position: 'absolute',
            top,
            zIndex: 2,
            paddingLeft: 3,
            paddingRight: 3,
            height: 24,
            justifyContent: 'center',
            backgroundColor: labelBGColor,
            ...(labelPosition === 'left' && {left: 16}),
            ...(labelPosition === 'right' && {right: 16}),
        };
    });

    const labelStyle = useAnimatedStyle(() => {
        const fontSize = interpolate(animatedIsFocusedValue.value, [0, 1], [16, 10], Extrapolation.IDENTITY);
        const marginTop = Platform.OS === 'android' ? 0 : interpolate(animatedIsFocusedValue.value, [0, 1], [-3, -0], Extrapolation.IDENTITY);

        return {
            fontSize,
            marginTop,
            color: labelColor,
        };
    });

    return (
        <Box flex={1} {..._wrapper}>
            {isVisible &&
            <Animated.View pointerEvents='none' style={labelContainerStyle}>
                <Animated.Text style={labelStyle}>
                    {`${label}${isRequired ? ' *' : ''}`}
                </Animated.Text>
            </Animated.View>
            }

            {props.children}
        </Box>
    );
};

export default FloatingLabelView;
