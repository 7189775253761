import {Box, Row, Text} from 'native-base';
import IcOldWamp from '@ui/svg/IcOldWamp';
import React from 'react';
import {useTranslation} from 'react-i18next';

const AuthInfoCard = () => {
    const {t} = useTranslation();

    return (
        <Row
            p={4}
            borderRadius="lg"
            bg='info.100'
            space={3}
        >
            <Box>
                <Box
                    bg='primary.500'
                    p={2}
                    borderRadius={16}
                >
                    <Box
                        alignItems='center'
                        justifyContent='center'
                        h={12}
                        w={12}
                    >
                        <IcOldWamp fill='white' height={28}/>
                    </Box>

                </Box>
            </Box>

            <Box flex={1}>
                <Text
                    color='primary.800'
                    variant="bodyLg"
                >
                    {t('auth.infoCard.text')}
                </Text>
            </Box>
        </Row>
    );
};

export default AuthInfoCard;
