import NavigationLink from '../buttons/NavigationLink';
import ParticipantAvatar from './ParticipantAvatar';
import React from 'react';
import {getScreen} from '@ui/provider/navigation/screens';
import {useUser} from '@ui/provider/user/UserProvider';

const CurrentUserParticipantAvatar = () => {
    const {user} = useUser();

    if(!user) {
        return null;
    }

    return (
        <NavigationLink path={getScreen('currentUserDetail')}>
            <ParticipantAvatar participant={user} _avatar={{size: 'sm'}} showName={false}/>
        </NavigationLink>
    );
};

export default CurrentUserParticipantAvatar;
